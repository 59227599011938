<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">
				<el-icon @click="backpath">
					<ArrowLeft />
				</el-icon>题目详情
			</div>
		</div>
		<el-scrollbar v-loading="loading">
			<div v-if="datainfo!=null">
				<!-- 大题名字 -->
				<div class="questionname">题目：{{datainfo.questionName}}</div>
				<div style="margin-top: 10px;">
					<el-button size="small" type="primary" @click="inaddpage(datainfo)">在此题目下增加题目</el-button>
				</div>
				<div class="questionname" v-if="datainfo.stem != null">题干：
				<div v-html="datainfo.stem"></div></div>
				<div v-if="datainfo.childQuestionDtoList!=null" class="xt"
					v-for="item in datainfo.childQuestionDtoList">
					<!-- 小题名字 -->
					<div style="margin-left: 20px;margin-top: 10px;">{{item.questionName}}</div>
					<div v-if="item.childQuestionDtoList!=null" v-for="(item2,index2) in item.childQuestionDtoList">
						<div style="margin-left: 40px;margin-top: 10px;">{{index2+1}}.{{item2.questionName}}</div>
						<div v-if="item2.childQuestionDtoList!=null"
							v-for="(item3,index3) in item2.childQuestionDtoList">
							<div style="margin-left: 60px;margin-top: 10px;">({{index3+1}}).{{item3.questionName}}</div>
							<div style="margin-top: 10px;box-sizing: border-box;padding-left: 60px;"
								v-if="item3.childQuestionDtoList == null">
								<!-- 题目解析 -->
								<div class="jx" v-if="item3.analysis!=null">
									<div v-html="item3.analysis"></div>
								</div>
								<!-- 参考答案 -->
								<div class="da" v-if="item3.answer!=null">
									<div v-html="item3.answer"></div>
								</div>
								<!-- 量规 -->
								<div class="lg">量规</div>
							</div>
						</div>
						<div v-else style="margin-top: 10px;box-sizing: border-box;padding-left: 40px;">
							<!-- 题目解析 -->
							<div class="jx" v-if="item2.analysis!=null">
								<div v-html="item2.analysis"></div>
							</div>
							<!-- 参考答案 -->
							<div class="da" v-if="item2.answer!=null">
								<div v-html="item2.answer"></div>
							</div>
							<!-- 量规 -->
							<div class="lg">量规</div>
						</div>
					</div>
					<div v-else style="margin-top: 10px;box-sizing: border-box;padding-left: 20px;">
						<!-- 题目解析 -->
						<div class="jx" v-if="item.analysis!=null">
							<div v-html="item.analysis"></div>
						</div>
						<!-- 参考答案 -->
						<div class="da" v-if="item.answer!=null">
							<div v-html="item.answer"></div>
						</div>
						<!-- 量规 -->
						<div class="lg">量规</div>
					</div>
				</div>
				<div style="background: #cccccc;" v-else>
					<!-- 题目解析 -->
					<div class="jx" v-if="datainfo.analysis!=null">
						<div v-html="datainfo.analysis"></div>
					</div>
					<!-- 参考答案 -->
					<div class="da" v-if="datainfo.answer!=null">
						<div v-html="datainfo.answer"></div>
					</div>
					<!-- 量规 -->
					<div class="lg">量规</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
	import {
		ArrowLeft
	} from '@element-plus/icons-vue'
	import {
		questionsquery
	} from "../../util/api"
	export default {
		components: {
			ArrowLeft
		},
		data() {
			return {
				datainfo: null,
				loading: false
			}
		},
		created() {
			this.getinfo(this.$route.query.id)
		},
		methods: {
			inaddpage(data){
				this.$router.push({
					path: '/home/questionadd',
					query: {
						id: data.id,
						name: data.questionName
					}
				})
			},
			getinfo(id) {
				this.loading = true
				questionsquery(id).then((res) => {
					this.datainfo = res.data
					this.loading = false
				})
			},
			backpath() {
				this.$router.back()
			}
		}
	}
</script>

<style scoped>
	.questionname {
		padding-top: 10px;
	}

	.xt {
		padding-top: 10px;
	}
.el-scrollbar{
		height: calc(100vh - 150px);
	}
	.jx {
		background: rgba(86, 219, 191, .2);
		box-sizing: border-box;
		padding: 15px;
	}

	.da {
		background: rgba(102, 196, 239, .2);
		box-sizing: border-box;
		padding: 15px;
		margin-top: 10px;
	}

	.lg {
		background: rgba(251, 201, 103, .2);
		box-sizing: border-box;
		padding: 15px;
		margin-top: 10px;
	}
</style>
